import { MailWrapperStyle } from './_style'
import { MailWrapperProps } from './_props'
import { Box } from '@mui/material'

export const MailWrapper = ({ url, children }: MailWrapperProps) => {
    return (
        <Box sx={MailWrapperStyle?.MailWrapperContent}>
            <Box sx={MailWrapperStyle.MailWrapperItem}>
                <iframe height="100%" width="100%" src={url}></iframe>
            </Box>
            <Box sx={MailWrapperStyle.MailWrapperItem}>{children}</Box>
        </Box>
    )
}
