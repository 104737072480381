import { requester } from '../../utils/requester/requester'

// Entry type for login request
export type LoginQueryType = {
    email: string
    password: string
}

export type LoginQueryResponse = {
    token: string
}

export const login = async (params: LoginQueryType) => {
    return await requester<LoginQueryResponse>('/auth/login', JSON.stringify(params), null, 'POST')
}
