export const NatureContentStyle = {
    display: 'flex',
}
export const NatureContentItemStyle = {
    flexBasis: '100%',
    margin: '1rem',
    height: '80vh',
}
export const NatureContentEditorStyle = {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
}

export const NatureContentObservation = {
    border: '1px solid gray',
    padding: '1rem',
    borderRadius: '20px',
    overflow: 'auto',
}
