import translations from './locale.json'

export const useGetTranslation = () => {
    const translationsMap = new Map(Object.entries(translations))
    const translate = (key: string, firstCharUpperCase = false) => {
        const value = translationsMap.get(key)
        if (!value) return key

        if (firstCharUpperCase) {
            const firstChar = value[0].toUpperCase()
            const rest = value.substring(1, value.length)
            return firstChar + rest
        }
        return value
    }
    return {
        t: translate,
        tu: (key: string) => translate(key, true),
    }
}
