export const ObservationCategoryValues = ['Oiseaux', 'Amphibiens', 'Papillons', 'Odonates', 'Mammifères', 'Insectes', 'Autres', 'Reptiles']
export type ObservationCategory = (typeof ObservationCategoryValues)[number]
export type Mails = 'nature' | 'simple' | 'free'
export type ObsCategory = {
    name: string
    items: Array<Observation>
}
export type Observation = {
    id: number
    category: ObservationCategory
    name: string
    href: string
}
export type Event = {
    title: string
    short: string
    date: string
    location: string
    description: string
}
export type MailImage = {
    media: File
    name: string
}
