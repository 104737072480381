import { Box, Button, TextField } from '@mui/material'
import { MailWrapper } from '../../../../components/mailWrapper/MailWrapper'
import { useGetTranslation } from '../../../../utils/i18n/useGetTranslation'
import { ChangeEvent, useEffect, useState } from 'react'
import { API_URL } from '../../../../utils/requester/requester'
import { useMailProvider } from '../../../../context/MailContext'
import { FreeMailStyle } from './_style'
import { Editor } from '../../../../components/editor/Editor'
import { uploadMailImage } from '../../../../api/queries/mailImage'

export const FreeMail = () => {
    const { t, tu } = useGetTranslation()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [url, setUrl] = useState('')
    const [photo, setPhoto] = useState('')
    const [picture, setPicture] = useState<File>()
    const [pictureUrl, setPictureUrl] = useState('')

    const { setContent } = useMailProvider()

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(evt.target.value)
    }
    const onDescChange = (value: string) => {
        setDescription(value)
    }

    const onClosePictureChooser = (evt: React.FormEvent<HTMLInputElement>) => {
        const target = evt.target as HTMLInputElement
        const files = target.files
        if (files) {
            setPicture(files[0])
            const fr = new FileReader()
            fr.onload = () => {
                if (fr.result) setPictureUrl(fr.result.toString())
            }
            fr.readAsDataURL(files[0])
        }
    }

    const uploadImage = async () => {
        const form = new FormData()
        if (picture) form.append('picture', picture)

        const res = await uploadMailImage(form)

        const name = res.data?.name
        if (name) setPhoto(name)
    }

    // Use Effects
    useEffect(() => {
        setUrl(encodeURI(`${API_URL}/mail/template?type=free&title=${title}&description=${description}&photo=${photo}`))
        setContent(`type=free&title=${title}&description=${description}&photo=${photo}`)
    }, [title, description, photo])

    return (
        <MailWrapper url={url}>
            <Box sx={FreeMailStyle.FreeMailEditor}>
                <TextField label={t('title', true)} onChange={evt => onTitleChange(evt)} />
                <Editor onChange={onDescChange} />
                <input style={{ display: 'none' }} id="picture" type="file" onChange={onClosePictureChooser} />
                <Button variant="contained" onClick={() => document.getElementById('picture')?.click()}>
                    {tu('picture')}
                </Button>
                <img style={{ width: '500px' }} src={pictureUrl} />
                <Button variant="contained" onClick={() => uploadImage()}>
                    {`${tu('upload')} ${t('et')} ${t('apply')}`}
                </Button>
            </Box>
        </MailWrapper>
    )
}
