import React, { createContext, useContext, useEffect, useState } from 'react'
import { whoami } from '../api/queries/whoami'
import { User } from '../api/types'
import { AuthType } from './_types'

const AuthContext = createContext<AuthType | null>(null)

const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const [sessionToken, setSessionToken] = useState<string | null>(localStorage.getItem('sessionToken'))
    const [loggedUser, setLoggedUser] = useState<User | null>(null)

    useEffect(() => {
        if (!sessionToken) localStorage.removeItem('sessionToken')

        if (localStorage.getItem('sessionToken') !== sessionToken) localStorage.setItem('sessionToken', sessionToken ?? '')

        if (sessionToken)
            whoami().then(user => {
                if (user) {
                    setLoggedUser(user)
                } else {
                    setSessionToken(null)
                }
            })
        else setLoggedUser(null)
    }, [sessionToken])

    return <AuthContext.Provider value={{ sessionToken, setSessionToken, loggedUser }}>{children}</AuthContext.Provider>
}

export const useAuthentication = () => {
    const context = useContext<AuthType | null>(AuthContext)

    if (context) {
        const { sessionToken, setSessionToken, loggedUser } = context
        return {
            setSessionToken: setSessionToken,
            sessionToken: sessionToken,
            loggedUser: loggedUser,
        }
    } else
        return {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setSessionToken: () => {},
            sessionToken: null,
            loggedUser: null,
        }
}

export default AuthProvider
