export const MenuStyles = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
}
export const MenuTitleStyles = {
    marginLeft: '3rem',
}
export const MenuTitleContainer = {
    display: 'flex',
    alignItems: 'center',
    margin: '3rem',
}
export const MenuContainerStyles = {
    display: 'flex',
    gap: '1rem',
    maxWidth: '70vw',
    flexWrap: 'wrap',
    justifyContent: 'center',
}
