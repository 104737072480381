import { Box, Button, Paper, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { login } from '../../api/queries/login'
import { useGetTranslation } from '../../utils/i18n/useGetTranslation'
import { LoginContainer, LoginPaper } from '../_styles'
import { useAuthentication } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { LoginStyles } from './_styles'

export const Login = () => {
    const { t } = useGetTranslation()
    const navigate = useNavigate()
    const { sessionToken, setSessionToken } = useAuthentication()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const onButtonHandler = () => {
        setLoading(true)
        login({
            password: password,
            email: email,
        }).then(response => {
            setLoading(false)

            if (response.data) {
                setSessionToken(response.data.token)
                navigate('/')
            }
        })
    }

    useEffect(() => {
        if (sessionToken) navigate('/')
    }, [sessionToken])

    return (
        <Box sx={LoginContainer}>
            <Paper sx={LoginPaper}>
                <TextField
                    variant="outlined"
                    label={t('email', true)}
                    onChange={evt => {
                        setEmail(evt.target.value)
                    }}
                ></TextField>
                <TextField
                    variant="outlined"
                    type="password"
                    label={t('password', true)}
                    onChange={evt => {
                        setPassword(evt.target.value)
                    }}
                    onKeyDown={key => {
                        if (key.key === 'Enter') onButtonHandler()
                    }}
                ></TextField>
                {!loading ? (
                    <Button variant="contained" onClick={onButtonHandler}>{`${t('se')} ${t('connect')}`}</Button>
                ) : (
                    <Box sx={LoginStyles}>
                        <CircularProgress />
                    </Box>
                )}
            </Paper>
        </Box>
    )
}
