import { makeSx } from '../../utils/utils'

export const MailWrapperStyle = makeSx({
    MailWrapperContent: {
        display: 'flex',
    },
    MailWrapperItem: {
        flexBasis: '100%',
        margin: '1rem',
        height: '80vh',
    },
})
