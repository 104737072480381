import { createContext, useContext, useState } from 'react'

type MailContextType = {
    content: string
    setContent: (value: string) => void
}

const defaultMailContext = {
    content: '',
    setContent: (value: string) => {
        console.log(value)
    },
}

const MailContext = createContext<MailContextType>(defaultMailContext)

const MailProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    // States
    const [mailContent, setMailContent] = useState<string>('')

    // Handlers
    const setContent = (value: string) => {
        setMailContent(encodeURI(value))
    }

    // Rendring
    return <MailContext.Provider value={{ content: mailContent, setContent }}>{children}</MailContext.Provider>
}

export const useMailProvider = () => {
    const context = useContext<MailContextType>(MailContext)

    return {
        content: context.content,
        setContent: context.setContent,
    }
}

export default MailProvider
