import React from 'react'
import { Card, CardActionArea, CardContent, Typography } from '@mui/material'
import { MenuItemsProps } from './_props'
import { MenuItemContentStyle, MenuItemStyle, MenuItemContentDisabledStyle } from './_styles'

export const MenuItem = ({ element }: MenuItemsProps) => {
    const onClickHandler = () => {
        if (element.redirect) {
            const a = document.createElement('a')
            a.href = element.redirect
            a.target = '_blank'
            a.click()
        }
        if (element.href) window.location.href = window.location.origin + element.href
    }

    if (element.disabled)
        return (
            <Card key={element.name} sx={MenuItemContentDisabledStyle}>
                <CardContent sx={MenuItemStyle}>
                    {element.icon}
                    <Typography>{element.title}</Typography>
                </CardContent>
            </Card>
        )

    return (
        <Card key={element.name} sx={MenuItemContentStyle}>
            <CardActionArea onClick={onClickHandler}>
                <CardContent sx={MenuItemStyle}>
                    {element.icon}
                    <Typography>{element.title}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
