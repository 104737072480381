import { Paper } from '@mui/material'
import React, { useState } from 'react'
import { EditorState } from 'draft-js'
import { Editor as WEditor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './editor.css'
import { stateToHTML } from 'draft-js-export-html'
import { useEffect } from 'react'

export const Editor = ({ onChange }: { onChange: any }) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

    useEffect(() => {
        const doc = stateToHTML(editorState.getCurrentContent())
        onChange(doc.replaceAll("&nbsp;",""))
    }, [editorState])

    return (
        <Paper className="wrapper-class">
            <WEditor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link'],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                    },
                }}
            />
        </Paper>
    )
}
