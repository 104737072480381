import React from 'react'
import { Menu } from './features/menu/Menu'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Mail } from './features/mail/Mail'
import ProtectedRoute from './components/router/ProtectedRouter'
import { Login } from './features/login/Login'
import AuthProvider from './context/AuthContext'
import { Event } from './features/event/Event'
import { Article } from './features/article/Article'

const theme = createTheme({
    palette: {
        background: {
            default: '#F3F2F2',
        },
    },
})

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<ProtectedRoute />}>
                            <Route path="/" element={<Menu />} />
                            <Route path="/email" element={<Mail />} />
                            <Route path="/event" element={<Event />} />
                            <Route path="/article" element={<Article />} />
                        </Route>
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App
