import { Box, TextField } from '@mui/material'
import { MailWrapper } from '../../../../components/mailWrapper/MailWrapper'
import { useGetTranslation } from '../../../../utils/i18n/useGetTranslation'
import { ChangeEvent, useEffect, useState } from 'react'
import { SimpleEventStyle } from './_style'
import { API_URL } from '../../../../utils/requester/requester'
import { useMailProvider } from '../../../../context/MailContext'

export const SimpleEvent = () => {
    const { t } = useGetTranslation()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [subDescription, setSubDescription] = useState('')
    const [url, setUrl] = useState('')
    const [photo, setPhoto] = useState('')

    const { setContent } = useMailProvider()

    const onTitleChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(evt.target.value)
    }
    const onDescChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(evt.target.value)
    }
    const onSubDescChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSubDescription(evt.target.value)
    }
    const onPictureChange = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhoto(evt.target.value)
    }

    // Use Effects
    useEffect(() => {
        setUrl(
            encodeURI(
                `${API_URL}/mail/template?type=simpleEvent&title=${title}&description=${description}&subDescription=${subDescription}&photo=${photo}`
            )
        )
        setContent(`type=simpleEvent&title=${title}&description=${description}&subDescription=${subDescription}&photo=${photo}`)
    }, [title, description, subDescription, photo])

    return (
        <MailWrapper url={url}>
            <Box sx={SimpleEventStyle.SimpleEventEditor}>
                <TextField label={t('title', true)} onChange={evt => onTitleChange(evt)} />
                <TextField label={t('description', true)} onChange={evt => onDescChange(evt)} />
                <TextField label={t('subDescription', true)} onChange={evt => onSubDescChange(evt)} />
                <TextField label={t('picture', true)} onChange={evt => onPictureChange(evt)} />
            </Box>
        </MailWrapper>
    )
}
