export const EventStyles = {
    margin: '3rem',
    padding: '1rem',
    borderRadius: '10px',
}

export const EventFieldContainesStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    maxWidth: '60%',
}

export const EventValidationButtonStyle = {
    margin: '1rem',
}
