export const MenuItemContentStyle = {
    textAlign: 'center',
}

export const MenuItemStyle = {
    maxWidth: 345,
}

export const MenuItemContentDisabledStyle = {
    maxWidth: 345,
    opacity: '60%',
}
