export const LoginContainer = {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
}

export const LoginPaper = {
    width: '60vw',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    padding: '4rem',
}
