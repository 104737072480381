export type RequestData<T> = {
    message?: string
    data?: T | null
}
export const API_URL = process.env.REACT_APP_API_URL + '/api'

export const requester = async <T>(
    url: string,
    body?: BodyInit | null,
    headers?: Record<string, string> | null,
    method?: string,
    isFormData?: boolean,
    isHtmlView?: boolean
): Promise<RequestData<T>> => {
    const sessionToken = localStorage.getItem('sessionToken')
    if (!headers && isFormData) headers = {}
    else headers = { 'Content-Type': 'application/json' }

    if (sessionToken) headers['authorization'] = 'Bearer ' + sessionToken

    let bodyRes = null

    try {
        const response = await fetch(API_URL + url, { body: body, method: method ?? 'GET', headers: headers })
        if (!isHtmlView) bodyRes = await response.json()
    } catch (err) {
        console.error(err)
        bodyRes = {
            message: 'Erreur avec le serveur !',
        }
    }
    return bodyRes
}
