import { Navigate, Outlet } from 'react-router-dom'
import { useAuthentication } from '../../context/AuthContext'

/**
 * This component will only return the child route if there is a session token
 */
const ProtectedRoute = () => {
    const { sessionToken } = useAuthentication()
    return sessionToken ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoute
