import React from 'react'
import { useGetTranslation } from '../../utils/i18n/useGetTranslation'
import { MenuItem } from '../../components/menuItem/MenuItem'
import WebIcon from '@mui/icons-material/Web'
import EventIcon from '@mui/icons-material/Event'
import TimelineIcon from '@mui/icons-material/Timeline'
import EmailIcon from '@mui/icons-material/Email'
import BookIcon from '@mui/icons-material/Book'
import PersonIcon from '@mui/icons-material/Person'
import { MenuStyles, MenuContainerStyles, MenuTitleStyles, MenuTitleContainer } from './_styles'
import { MenuElementType } from '../../components/menuItem/_props'
import { Box, Typography } from '@mui/material'
import logo from '../../static/logo.png'
import { MenuHeader } from '../../components/menuHeader/MenuHeader'
import ArticleIcon from '@mui/icons-material/Article'

export const Menu = () => {
    const { t } = useGetTranslation()

    const menuItems: Array<MenuElementType> = [
        {
            name: 'site',
            title: t('site'),
            icon: <WebIcon />,
            redirect: 'https://ponse.fr',
        },
        {
            name: 'orsa',
            title: t('orsa'),
            icon: <TimelineIcon />,
            disabled: true,
        },
        {
            name: 'event',
            title: `${t('add', true)} ${t('event')}`,
            icon: <EventIcon />,
            href: '/event',
        },
        {
            name: 'article',
            title: `${t('add', true)} ${t('article')}`,
            icon: <ArticleIcon />,
            href: '/article',
        },
        {
            name: 'email',
            title: `${t('send', true)} ${t('email ')}`,
            icon: <EmailIcon />,
            href: '/email',
        },
        {
            name: 'contact',
            title: `${t('import', true)} ${t('contacts')}`,
            icon: <PersonIcon />,
            href: '/contact',
            disabled: true,
        },
        {
            name: 'documentation',
            title: `${t('consult', true)} ${t('documentation')}`,
            icon: <BookIcon />,
            redirect: 'https://docs.google.com/document/d/15jIQsEkSc_jjFK4VFtrREXtwGUBqnvef6SPqTYOZzX4/edit',
        },
    ]

    return (
        <Box>
            <MenuHeader />

            <Box sx={MenuStyles}>
                <Box sx={MenuTitleContainer}>
                    <img style={{ height: '150px' }} src={logo} />
                    <Typography variant="h4" sx={MenuTitleStyles}>
                        {t('menu_title')}
                    </Typography>
                </Box>
                <Box sx={MenuContainerStyles}>
                    {menuItems.map(e => {
                        return <MenuItem key={e.name} element={e} />
                    })}
                </Box>
            </Box>
        </Box>
    )
}
