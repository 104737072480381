export const ArticleStyles = {
    margin: '3rem',
    padding: '1rem',
    borderRadius: '10px',
}

export const ArticleFieldContainesStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    maxWidth: '60%',
}

export const ArticleValidationButtonStyle = {
    margin: '1rem',
}

export const ArticleImgPreview = {
    maxWidth: '20rem',
}
